/* eslint-disable import/prefer-default-export */
import { graphQuery } from './graphql';

export async function getCities(zip) {
  const query = `query($zip: String!) {
    cities (zip: $zip) {
      name
    }
  }`;

  const res = await graphQuery({ query, variables: { zip } });

  if (!res?.data?.cities) {
    throw new Error('Unable to read API response');
  }

  return res.data.cities?.map((city) => city.name).sort() ?? [];
}

const cacheStores = {};
let cacheAllStore;

export async function getStores(provider, storeGroup) {
  // Read cache
  if (storeGroup && cacheStores?.[storeGroup]) {
    return cacheStores[storeGroup];
  }
  if (!storeGroup && cacheAllStore) {
    return cacheAllStore;
  }

  const query = `query($provider: Provider!, $storeGroup: StoreGroup) {
    providerStores(provider: $provider, storeGroup: $storeGroup) {
      id
      label
    }
  }`;

  const res = await graphQuery({ query, variables: { provider, storeGroup } });

  if (!res?.data?.providerStores) {
    throw new Error('Unable to read API response');
  }

  const stores = res.data.providerStores.map(({ id, label }) => ({
    label,
    value: id,
  }));

  // Write cache
  if (storeGroup) {
    cacheStores[storeGroup] = stores;
  } else {
    cacheAllStore = stores;
  }

  return stores;
}

export async function sendCallRequest(values) {
  const { provider, providerStore, consent, ...rest } = values;

  const variables = {
    ...rest,
    ...(provider
      ? {
          additionalData: {
            provider,
            providerStore,
            consent,
          },
        }
      : {}),
  };

  const query = `mutation(
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $phoneNumber: String!,
    $zip: String!,
    $city: String!,
    $additionalData: Json,
    ){
    askToBeCalled(input: {
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      phoneNumber: $phoneNumber,
      zipCode: $zip,
      city: $city,
      additionalData: $additionalData,
    }) {
      success
    }
  }`;

  const res = await graphQuery({ query, variables });

  if (!res?.data?.askToBeCalled) {
    throw new Error('Unable to send call request');
  }

  return true;
}
