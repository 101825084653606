/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import ProviderStoreSelect from '../Form/ProviderStoreSelect';
import Checkbox from '../Form/Checkbox';
import Mask from '../Form/Mask';
import Text from '../Form/Text';
import City from '../Form/City';

function CallRequest({
  onSubmit,
  provider,
  storeGroup,
  cguLabel,
  consentLabel,
  providerStoreLabels,
  defaultValues = {},
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { errors, formState, handleSubmit, register, watch, control } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const zipCode = watch('zip');

  const handleFormSubmit = async (data) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      await onSubmit(data);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="container-fluid my-1">
        <div className="row pt-3">
          <div className="col-12 mainText medium textHeightS fontWeightB">
            Faites vous rappeler par un Conseiller
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-12 pt-4">
            <Text
              name="firstName"
              label="Prénom"
              register={register({ required: 'Veuillez renseigner votre prénom' })}
              error={errors?.firstName?.message}
              className="mt-4"
            />
            <Text
              name="lastName"
              label="Nom"
              register={register({ required: 'Veuillez renseigner votre nom' })}
              error={errors?.lastName?.message}
              className="mt-4"
            />
            <Text
              name="email"
              label="E-mail"
              register={register({
                required: 'Veuillez renseigner votre e-mail',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Veuillez renseigner un e-mail valide',
                },
              })}
              error={formState.touched?.email && errors?.email?.message}
              className="mt-4"
            />

            <Mask
              name="phoneNumber"
              label="Téléphone"
              mask="99 99 99 99 99"
              className="mt-4"
              control={control}
              rules={{
                required: 'Veuillez renseigner votre numéro de téléphone',
                pattern: {
                  value: /^0[0-9]{9}$/,
                  message: 'Veuillez renseigner un numéro de téléphone valide',
                },
              }}
              transform={{
                output: (value) =>
                  value?.replaceAll(' ', '').replaceAll('_', '') ?? value,
              }}
              error={formState.touched?.phoneNumber && errors?.phoneNumber?.message}
            />

            <Mask
              name="zip"
              label="Code postal"
              mask="99999"
              className="mt-4"
              control={control}
              rules={{
                required: 'Veuillez renseigner votre code postal',
                validate: {
                  valid: (value) =>
                    value?.length === 5 || 'Veuillez renseigner un code postal valide',
                  domTom: (value) =>
                    (!value.startsWith('97') && !value.startsWith('98')) ||
                    'Les DOM/TOM ne sont pas éligibles',
                },
              }}
              transform={{
                output: (value) => value?.replaceAll('_', '') ?? value,
              }}
              error={formState.touched?.zip && errors?.zip?.message}
            />

            <City
              name="city"
              label="Ville"
              className="mt-4"
              control={control}
              zipCode={zipCode}
              rules={{
                required: 'Veuillez sélectionner une ville',
              }}
              error={errors?.city?.message}
            />

            {providerStoreLabels && (
              <ProviderStoreSelect
                name="providerStore"
                provider={provider}
                storeGroup={storeGroup}
                className="mt-4"
                control={control}
                rules={{
                  required: 'Veuillez sélectionner une valeur',
                }}
                error={errors?.providerStore?.message}
                label={providerStoreLabels}
              />
            )}

            <Checkbox
              name="cgu"
              register={register({ required: 'Vous devez accepter les conditions' })}
              error={errors?.cgu?.message}
              label={cguLabel}
            />

            {consentLabel !== null && (
              <Checkbox
                name="consent"
                register={register({ required: false })}
                error={errors?.consent?.message}
                label={consentLabel}
              />
            )}

            <button
              type="submit"
              disabled={!formState.isValid || isSubmitting}
              className={`button primary small_shadow mt-5 w-100 ${
                !formState.isValid || isSubmitting ? 'disabled' : ''
              }`}
            >
              {isSubmitting ? 'Envoi en cours...' : 'Être rappelé'}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

CallRequest.defaultProps = {
  provider: null,
  storeGroup: null,
  providerStoreLabels: null,
  cguLabel: null,
  consentLabel: null,
};

CallRequest.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  provider: PropTypes.string,
  storeGroup: PropTypes.string,
  providerStoreLabels: PropTypes.string,
  cguLabel: PropTypes.string,
  consentLabel: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: PropTypes.object,
};

export default CallRequest;
